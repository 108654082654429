ion-icon {
    &[class*="ca-"] {
        // Instead of using the font-based icons // We're applying SVG masks
        mask-size: contain;
        mask-position: 50% 50%;
        mask-repeat: no-repeat;
        background: currentColor;
        width: 1em;
        height: 1em;
    }
    input {
        border-radius: 0px;
    }
    //usedIconsz
    // custom icons
    &[class*="ca-star"] {
        mask-image: url(../assets/icon/star-copy-4.svg);
        color: #ffffff;
    }
    &[class*="ca-star-outline"] {
        mask-image: url(../assets/icon/star-copy-4.svg);
        color: var(--ion-color-primary);
    }
    &[class*="ca-star-filled"] {
        mask-image: url(../assets/icon/star-filled.svg);
        color: var(--ion-color-primary);
    }
    &[class*="ca-down-arrow-yellow-icon"] {
        mask-image: url(../assets/icon/ca-down-arrow-yellow.svg);
        color: var(--ion-color-primary);
        font-size: 14px;
    }
    &[class*="ca-up-arrow-yellow-icon"] {
        mask-image: url(../assets/icon/ca-up-arrow-yellow.svg);
        color: var(--ion-color-primary);
        font-size: 14px;
    }
    &[class*="ca-calendar-yellow-icon"] {
        mask-image: url(../assets/icon/ca-calendar-yellow.svg);
    }
    &[class*="ca-call"] {
        mask-image: url(../assets/icon/shape-copy-4.svg);
    }
    &[class*="ca-verified"] {
        mask-image: url(../assets/icon/verified.svg);
        width: 22px;
        height: 22px;
        background-color: var(--ion-color-primary);
    }
    &[class*="ca-info"] {
        mask-image: url(../assets/icon/info.svg);
        color: var(--ion-color-primary);
    }
    &[class*="ca-back"] {
        mask-image: url(../assets/icon/backbutton.svg);
        color: var(--ion-text-color);
    }
    &[class*="ca-activity-off-icon"] {
        mask-image: url(../assets/icon/activities-tab.svg);
        color: var(--ion-color-primary);
    }
    &[class*="ca-right-arrow-icon"] {
        mask-image: url(../assets/icon/rightArrow.svg);
        color: var(--ion-color-primary);
        font-size: 14px;
    }
    &[class*="ca-close-icon"] {
        mask-image: url(../assets/icon/_ionicons_svg_md-close.svg);
    }
    &[class*="ca-stroke-dine"] {
        mask-image: url(../assets/icon/stroke-dine.svg);
        color: var(--ion-color-primary);
    }
    &[class*="ca-search-icon"] {
        mask-image: url(../assets/icon/search-icon.svg);
        font-size: 2.33rem;
    }
    &[class*="ca-call-service-icon"] {
        mask-image: url(../assets/icon/call-orange-icon.svg);
        font-size: 3rem;
    }
    &[class*="ca-applauds-hand-icon"] {
        mask-image: url(../assets/icon/applauds-hand.svg);
        font-size: 1.67rem;
    }
    &[class*="ca-add-icon"] {
        mask-image: url(../assets/icon/add-symbol.svg);
        color: var(--ion-color-primary);
    }
    &[class*="ca-minus-icon"] {
        mask-image: url(../assets/icon/minus-symbol.svg);
        color: var(--ion-color-primary);
    }
    &[class*="ca-edit-icon__colored"] {
        mask-image: url(../assets/icon/Update.svg);
        font-size: 1.33rem;
        color: var(--ion-color-primary);
    }
    &[class*="ca-rightarrow"] {
        mask-image: url(../assets/icon/line-4-copy-3.svg);
        font-size: 14px;
    }
    &[class*="ca-logout-icon"] {
        mask-image: url(../assets/icon/logout-copy.svg);
        width: 19.1px;
        height: 15.1px;
    }
    &[class*="ca-notifications-icon"] {
        mask-image: url(../assets/icon/notifications.svg);
    }
    &[class*="ca-unlock-icon"] {
        mask-image: url(../assets/icon/group-37.svg);
    }
    &[class*="ca-exception"] {
        mask-image: url(../assets/icon/group-32.svg);
    }
    &[class*="ca-Scanner"] {
        mask-image: url(../assets/icon/Scanner.svg);
    }
    &[class*="ca-delay-icon"] {
        mask-image: url(../assets/icon/group-36.svg);
    }
    &[class*="ca-exclamation"] {
        mask-image: url(../assets/icon/exclamation_Icon.svg);
    }
    &[class*="ca-privacy-icon"] {
        mask-image: url(../assets/icon/privacy-icon.svg);
    }
    &[class*="ca-disclaimer-icon"] {
        mask-image: url(../assets/icon/disclaimer-icon.svg);
    }
    &[class*="ca-food-pref-icon"] {
        mask-image: url(../assets/icon/food-pref-icon.svg);
    }
    &[class*="ca-beverage-icon"] {
        mask-image: url(../assets/icon/beverage-icon.svg);
    }
    &[class*="ca-confirm-outline"] {
        mask-image: url(../assets/icon/outline-check.svg);
    }
    &[class*="ca-cancel-outline"] {
        mask-image: url(../assets/icon/close-outline.svg);
    }
    &[class*="ca-email-outline"] {
        mask-image: url(../assets/icon/group-16.svg);
        font-size: 3rem;
    }
    &[class*="ca-modal-close"] {
        mask-image: url(../assets/icon/close.svg);
        font-size: 25px !important;
    }
    &[class*="ca-chat"] {
        mask-image: url(../assets/icon/chatnew.svg);
        font-size: 16px;
        color: var(--ion-color-primary);
    }
    &[class*="ca-phone"] {
        mask-image: url(../assets/icon/telephone.svg);
        font-size: 16px;
        color: var(--ion-color-primary);
    }
    &[class*="ca-view"] {
        mask-image: url(../assets/icon/file.svg);
        font-size: 16px;
        color: var(--ion-color-primary);
    }
    &[class*="ca-plus-icon"] {
        mask-image: url(../assets/icon/ionicons_svg_add.svg);
    }
    &[class*="ca-checkmark-icon"] {
        mask-image: url(../assets/icon/ionicons_svg_checkmark.svg);
    }
    &[class*="ca-privacy-shield"] {
        mask-image: url(../assets/icon/Shield.svg);
    }
    &[class*="ca-setting-email"] {
        mask-image: url(../assets/icon/setting_Email.svg);
    }
    &[class*="ca-offer-events"] {
        mask-image: url(../assets/icon/offer-icon.svg);
    }
    &[class*="ca-outlet"] {
        mask-image: url(../assets/icon/outlet-icon.svg);
    }
    &[class*="ca-delivery"] {
        mask-image: url(../assets/icon/delivery-icon.svg);
    }
    &[class*="ca-takeaway"] {
        mask-image: url(../assets/icon/takeaway-icon.svg);
    }
    &[class*="ca-gift"] {
        mask-image: url(../assets/icon/gift-icon.svg);
    }
    &[class*="ca-call-icon"] {
        mask-image: url(../assets/icon/call-icon.svg);
    }
    &[class*="ca-promo-code"] {
        mask-image: url(../assets/icon/promo-code-icon.svg);
    }
    &[class*="ca-round-check"] {
        mask-image: url(../assets/icon/round-check.svg);
    }
    &[class*="ca-dine"] {
        mask-image: url(../assets/icon/add-cutlery-icon.svg);
    }
    &[class*="ca-location"] {
        mask-image: url(../assets/icon/location.svg);
    }
    &[class*="ca-question-mark"] {
        mask-image: url(../assets/icon/question-mark-icon.svg);
    }
    &[class*="ca-upload"] {
        mask-image: url(../assets/icon/upload-icon.svg);
    }
    &[class*="ca-delete"] {
        mask-image: url(../assets/icon/delete-icon.svg);
        color: #ffffff;
    }
    &[class*="ca-arrow-up-round"] {
        mask-image: url(../assets/icon/arrow-up-round.svg);
        color: #ffffff;
    }
    &[class*="ca-arrow-down-round"] {
        mask-image: url(../assets/icon/arrow-down-round.svg);
        color: #ffffff;
    }
    &[class*="ca-reorder"] {
        mask-image: url(../assets/icon/reorder.svg);
        color: #ffffff;
    }
    &[class*="ca-edit-icon"] {
        mask-image: url(../assets/icon/edit-icon.svg);
        color: #ffffff;
    }
    &[class*="ca-chef-favourite"] {
        mask-image: url(../assets/icon/chef-favourite.svg);
    }
    &[class*="ca-popular"] {
        mask-image: url(../assets/icon/popular-icon.svg);
    }
    &[class*="ca-guest-applauds"] {
        mask-image: url(../assets/icon/guest-applauds-icon.svg);
    }
    &[class*="ca-serves"] {
        mask-image: url(../assets/icon/serves-icon.svg);
    }
    &[class*="ca-spicy"] {
        mask-image: url(../assets/icon/spicy-icon.svg);
    }
    &[class*="ca-gluten"] {
        mask-image: url(../assets/icon/gluten-icon.svg);
    }
    &[class*="ca-nuts"] {
        mask-image: url(../assets/icon/nuts-icon.svg);
    }
    &[class*="ca-soya"] {
        mask-image: url(../assets/icon/soya-icon.svg);
    }
    &[class*="ca-prev"] {
        mask-image: url(../assets/icon/prev-icon.svg);
        color: #767676;
    }
    &[class*="ca-next"] {
        mask-image: url(../assets/icon/next-icon.svg);
        color: #767676;
    }
    &[class*="ca-app-version"] {
        mask-image: url(../assets/icon/app-version.svg);
        color: #767676;
    }
    &[class*="ca-play-icon"] {
        mask-image: url(../assets/icon/play-icon.svg);
    }
    &[class*="ca-pause-icon"] {
        mask-image: url(../assets/icon/pause-icon.svg);
    }
}
